import React from "react";
import { initializeApp } from 'firebase/app';
import { getAuth, User} from 'firebase/auth';
import { getDatabase, ref, get, update, push } from 'firebase/database';
import { getFunctions } from 'firebase/functions';
import { getAnalytics } from "firebase/analytics";
import { connect } from "react-redux";
import { authActions } from "../modules/Authentication/store/actions";
import { getStorage } from "@firebase/storage";

export const firebaseConfig = {
    apiKey: "AIzaSyDM9mODDuncJj6_K2_PTOYwxoyQqfYTBfE",
    authDomain: "estro-caalendario.firebaseapp.com",
    databaseURL: "https://estro-caalendario-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "estro-caalendario",
    storageBucket: "estro-caalendario.appspot.com",
    messagingSenderId: "336681192888",
    appId: "1:336681192888:web:738bcc446b5ecdb88f934d",
    measurementId: "G-M4GSGKE4NB"
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);

export const auth = getAuth();
export const database = getDatabase();
export const functions = getFunctions(undefined, "europe-west1");
export const storage = getStorage();


type Props = {
  authenticationSuccess: (userData: User) => void,
  /* failed: (message: string) => void, */
};

type State = {
};

class Authentication extends React.Component<Props, State> {

  componentDidMount() {
    auth.onAuthStateChanged(async (userData) => {
        console.log("User data", userData);
		if(userData !== null) {
			this.props.authenticationSuccess(userData);
			// get user data 
			get(ref(database, `users/${userData.uid}`))
			.then(data => {
				if(!data.val() || !data.val()["e"]) {
					update(ref(database, `users/${userData.uid}`), {
						e: userData.email,
					})
				}
			})
			.catch(err => {
				console.log("[firebase.tsx] error getting user data:", err);
			});

			const nowTimestamp = Math.round(new Date().getTime()/1000);
			push(ref(database, `data-logging/${userData.uid}/a`), {
				t: nowTimestamp,
			})
			.then(() => {
				console.log("[firebase] a. push success");
			})
			.catch(err => {
				console.log("[firebase] error pushing a.:", err);
			})
		}
		else {
			console.log("We did not authenticate.");
		}
    });
  }

  render() {
      return (
        <></>
      );
  }
}


const mapDispatchToProps = (dispatch: any) => {
    return {
      authenticationSuccess: (userData: User) => {
        dispatch(authActions.authenticationSuccess(userData));
      },
    }
}

export default connect(null, mapDispatchToProps)(Authentication);