import { ref as storageRef, getDownloadURL } from "firebase/storage";
import { storage } from "../../../common/firebase";

import mondayItit from "../../../assets/images/it_it/daysNames/monday.png";
import tuesdayItit from "../../../assets/images/it_it/daysNames/tuesday.png";
import wednesdayItit from "../../../assets/images/it_it/daysNames/wednesday.png";
import thursdayItit from "../../../assets/images/it_it/daysNames/thursday.png";
import fridayItit from "../../../assets/images/it_it/daysNames/friday.png";
import saturdayItit from "../../../assets/images/it_it/daysNames/saturday.png";
import sundayItit from "../../../assets/images/it_it/daysNames/sunday.png";

import oneItIt from "../../../assets/images/it_it/daysNumbers/one.png";
import twoItIt from "../../../assets/images/it_it/daysNumbers/two.png";
import threeItIt from "../../../assets/images/it_it/daysNumbers/three.png";
import fourItIt from "../../../assets/images/it_it/daysNumbers/four.png";
import fiveItIt from "../../../assets/images/it_it/daysNumbers/five.png";
import sixItIt from "../../../assets/images/it_it/daysNumbers/six.png";
import sevenItIt from "../../../assets/images/it_it/daysNumbers/seven.png";
import eightItIt from "../../../assets/images/it_it/daysNumbers/eight.png";
import nineItIt from "../../../assets/images/it_it/daysNumbers/nine.png";
import tenItIt from "../../../assets/images/it_it/daysNumbers/ten.png";
import elevenItIt from "../../../assets/images/it_it/daysNumbers/eleven.png";
import twelveItIt from "../../../assets/images/it_it/daysNumbers/twelve.png";
import thirteenItIt from "../../../assets/images/it_it/daysNumbers/thirteen.png";
import fourteenItIt from "../../../assets/images/it_it/daysNumbers/fourteen.png";
import fifteenItIt from "../../../assets/images/it_it/daysNumbers/fifteen.png";
import sixteenItIt from "../../../assets/images/it_it/daysNumbers/sixteen.png";
import seveneenItIt from "../../../assets/images/it_it/daysNumbers/seventeen.png";
import eighteenItIt from "../../../assets/images/it_it/daysNumbers/eighteen.png";
import nineteenItIt from "../../../assets/images/it_it/daysNumbers/nineteen.png";
import twentyItIt from "../../../assets/images/it_it/daysNumbers/twenty.png";
import twentyoneItIt from "../../../assets/images/it_it/daysNumbers/twentyone.png";
import twentytwoItIt from "../../../assets/images/it_it/daysNumbers/twentytwo.png";
import twentythreeItIt from "../../../assets/images/it_it/daysNumbers/twentythree.png";
import twentyfourItIt from "../../../assets/images/it_it/daysNumbers/twentyfour.png";
import twentyfiveItIt from "../../../assets/images/it_it/daysNumbers/twentyfive.png";

import decemberItIt from "../../../assets/images/it_it/monthsNames/december.png";
import marchItIt from "../../../assets/images/it_it/monthsNames/march.png";


export type Card = {
    id: string,
    d: number,
    t?: string,
    y?: string,
    f?: string,
    i?: string,
    o: boolean,
}

export type Calendar = {
    id: string,
    c: Card[],
    t: string,
    settings: CalendarSettings
    creationTimestamp: number,
}

export type CalendarSettings = {
    pictogramCardIconVisible: boolean,
    soundOnCardTap: boolean,
    detailedBackground: boolean,
}

const itDaysImagesArray = [sundayItit, mondayItit, tuesdayItit, wednesdayItit, thursdayItit, fridayItit, saturdayItit];
const itDaysNamesArray = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];
const itDaysNubersArray = [
    oneItIt, twoItIt, threeItIt, fourItIt, fiveItIt, sixItIt, sevenItIt, eightItIt, nineItIt,
    tenItIt, elevenItIt, twelveItIt, thirteenItIt, fourteenItIt, fifteenItIt, sixteenItIt, seveneenItIt, eighteenItIt, nineteenItIt,
    twentyItIt, twentyoneItIt, twentytwoItIt, twentythreeItIt, twentyfourItIt, twentyfiveItIt
]

export function fromCalendarDbStructureToCalendar(calendarId: string, calendarDbData: any) {
    const calendarTitle = calendarDbData.t;
    const calendarCreationTimestamp = calendarDbData.r;

    const calendarSettings: CalendarSettings = {
        soundOnCardTap: calendarDbData.s && ((calendarDbData.s.s !== null) && calendarDbData.s.s !== undefined) ? calendarDbData.s.s : true,
        pictogramCardIconVisible: calendarDbData.s && ((calendarDbData.s.p !== null) && calendarDbData.s.p !== undefined) ? calendarDbData.s.p : true,
        detailedBackground: calendarDbData.s && ((calendarDbData.s.b !== null) && calendarDbData.s.b !== undefined) ? calendarDbData.s.b : true,
    };


    const cardsIds = Object.keys(calendarDbData.c);
    const tempCards: Card[] = [];
    for (let j = 0; j < cardsIds.length; j++) {
        const cardContent = calendarDbData.c[cardsIds[j]];
        tempCards.push({
            id: cardsIds[j],
            t: cardContent.t,
            y: cardContent.y,
            f: cardContent.f,
            i: cardContent.i,
            d: cardContent.d,
            o: cardContent.o,
        });
    }
    const calendarToReturn: Calendar = {
        id: calendarId,
        t: calendarTitle,
        creationTimestamp: calendarCreationTimestamp,
        settings: calendarSettings,
        c: tempCards,
    };
    return calendarToReturn;
}

export function fromGSUrltoUrl(gsUrl: string) {
    return new Promise<string>((resolve, reject) => {
        getDownloadURL(storageRef(storage, gsUrl))
            .then(url => {
                console.log('[fromGSUrltoUrl] service - url:', url);
                resolve(url);
            })
            .catch(err => {
                console.log('[fromGSUrltoUrl] service error:', err);
                reject(err);
            })
    });
}

export function fromDayNumberToImg(dayNumber: number) {
    if ((dayNumber > -1) && (dayNumber < 7)) {
        return itDaysImagesArray[dayNumber];
    }
    else {
        return itDaysImagesArray[dayNumber % 7];
    }
}

export function fromDayNumberToDayName(dayNumber: number) {
    if ((dayNumber > -1) && (dayNumber < 7)) {
        return itDaysNamesArray[dayNumber];
    }
    else {
        return itDaysNamesArray[dayNumber % 7];
    }
}

export function fromDayNumberToDayNumberImage(dayNumber: number) {
    if ((dayNumber > 0) && (dayNumber < 26)) {
        return itDaysNubersArray[dayNumber - 1];
    }
    else {
        return itDaysNubersArray[0];
    }
}

export function fromMonthNumberToMonthImage(dayNumber: number) {
    //return decemberItIt;
    return marchItIt;
}