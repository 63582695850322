import {
    IonContent, IonTitle, IonPage, IonToolbar, IonHeader,
    IonGrid, IonButton, IonPopover, IonIcon, IonCol, IonRow, IonButtons,
    IonMenu, IonMenuButton, IonList, IonItem, IonSelect,
    IonSelectOption, IonLabel, IonInput, IonTextarea, IonAlert,
    IonToggle, IonItemDivider, IonImg,
} from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import { isPlatform, menuController } from '@ionic/core';
import { auth, database, storage } from '../../common/firebase';
import { get, ref, set, update, ref as databaseRef } from '@firebase/database';
import { Calendar, Card, fromCalendarDbStructureToCalendar, fromDayNumberToDayName, fromDayNumberToDayNumberImage, fromDayNumberToImg, fromGSUrltoUrl, fromMonthNumberToMonthImage } from '../Calendar/utils/calendarUtils';
import { closeOutline } from 'ionicons/icons';
import { deleteObject, ref as storageRef, uploadBytes } from 'firebase/storage';
import "./CalendarEditor.css";

type Props = {
    history: any,
};

type State = {
    currentMonth: number,
    currentDay: number,

    calendar: Calendar | null,
    userId: string | null,
    showDayPopover: boolean,
    dayContent: Card | null,
    dayIndex: number,
    mediaType: string,
    showDiscardAlert: boolean,
    didEditDay: boolean,
    videoId: string,
    imgUrl: string | undefined,
    uploadedImagesIds: string[],
    fileUrl: string
};

class CalendarEditor extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            currentMonth: 0,
            currentDay: 0,

            calendar: null,
            userId: null,
            showDayPopover: false,
            dayContent: null,
            dayIndex: 0,
            mediaType: '',
            showDiscardAlert: false,
            didEditDay: false,
            videoId: '',
            imgUrl: '',
            uploadedImagesIds: [],
            fileUrl: '',
        }
    }
    getUserCalendar(userId: string, calendarId: string) {
        console.log(`calendars/advent/${userId}/${calendarId}/`);

        get(databaseRef(database, `calendars/advent/${userId}/${calendarId}/`))
            .then((snapshot) => {
                if (snapshot.exists()) {
                    console.log("[CalendarEditor] Snapshot", snapshot.val());

                    let tempCalendar: Calendar;
                    tempCalendar = fromCalendarDbStructureToCalendar(calendarId, snapshot.val());
                    this.setState({ calendar: tempCalendar });
                }
                else {
                    console.log("[CalendarEditor] No user's calendars");
                    this.setState({ calendar: null });
                }
            })
            .catch(err => {
                console.log("[CalendarEditor] error getting user's calendars:", err);
            })
    }

    componentDidMount() {
        auth.onAuthStateChanged(userData => {
            if (userData) {
                this.setState({ userId: userData.uid });
                if (this.props.history.location.state && this.props.history.location.state.calendar && this.props.history.location.state.calendar.id) {
                    this.getUserCalendar(userData.uid, this.props.history.location.state.calendar.id)
                }
                else {
                    console.log("[CalendarEditor] Calendar not found");
                }
                let today = new Date();
                this.setState({ currentMonth: today.getMonth() + 1, currentDay: today.getDate() })
            }
            else {
                this.setState({ userId: null });
            }
        })
    }

    getMediaType(card: Card) {
        return card.f ? 'f' : card.i ? 'i' : card.t ? 't' : 'y';
    }

    openMenu() {
        menuController.open()
    }

    updateCalendarTitle(newTitle: string) {
        if (newTitle === '')
            newTitle = 'Inserisci un titolo calendario'
        update(ref(database, `/calendars/advent/${this.state.userId}/${this.state.calendar?.id}/`), { t: newTitle })
        this.state.calendar!.t = newTitle;
    }

    updateCardOnDb() {
        if (this.state.dayContent) {
            const tempContent = {}
            console.log(Object.keys(this.state.dayContent))
            Object.keys(this.state.dayContent).forEach((key, i) => {
                if (key === 'id')
                    return
                else {
                    Object.assign(tempContent, { [key]: Object.values(this.state.dayContent!)[i] })
                }
            })
            set(ref(database, `/calendars/advent/${this.state.userId}/${this.state.calendar?.id}/c/${this.state.dayContent.id}/`), tempContent)
        }
    }

    updateDayContentState(type: string, value: string) {
        if (this.state.dayContent) {
            switch (type) {
                case 't': {
                    this.setState({
                        dayContent: {
                            id: this.state.dayContent.id,
                            o: this.state.dayContent.o,
                            d: this.state.dayContent.d,
                            t: value,
                        }
                    })
                    break;
                }
                case 'y': {
                    this.setState({
                        dayContent: {
                            id: this.state.dayContent.id,
                            o: this.state.dayContent.o,
                            d: this.state.dayContent.d,
                            y: value,
                        }
                    })
                    break;
                }
                case 'i': {
                    this.setState({
                        dayContent: {
                            id: this.state.dayContent.id,
                            o: this.state.dayContent.o,
                            d: this.state.dayContent.d,
                            i: value,
                        }
                    })
                    break;
                }
                case 'f': {
                    this.setState({
                        dayContent: {
                            id: this.state.dayContent.id,
                            o: this.state.dayContent.o,
                            d: this.state.dayContent.d,
                            f: value,
                        }
                    })
                    break;
                }
                default:
                    break;
            }
        }
    }

    isFieldEmpty() {
        switch (this.state.mediaType) {
            case 't': {
                if (!this.state.dayContent?.t)
                    return true
                break;
            }
            case 'y': {
                if (!this.state.dayContent?.y)
                    return true
                break;
            }
            case 'f': {
                if (!this.state.dayContent?.f)
                    return true
                break;
            }
            case 'i': {
                if (!this.state.dayContent?.i)
                    return true
                break;
            }
            default:
                break;
        }
    }
    makeId(length: number) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    uploadMedia(media: File | null | undefined) {
        if (media && this.state.calendar) {
            if (this.state.mediaType === 'i') {
                uploadBytes(storageRef(storage, `users-media/${this.state.userId}/calendars/advent/${this.state.calendar.id}/${this.makeId(16)}`), media)
                    .then(data => {
                        console.log("[Utils] upload success:", data.ref);
                        this.updateDayContentState(this.state.mediaType, data.ref.fullPath);
                        this.state.uploadedImagesIds.push(data.ref.name)
                        fromGSUrltoUrl(data.ref.fullPath)
                            .then(url => {
                                this.setState({ imgUrl: url })
                            })
                    })
                    .catch(err => {
                        console.log("[Utils] error uploading file", err);
                    })
            }
            else if (this.state.mediaType === 'f') {
                uploadBytes(storageRef(storage, `users-media/${this.state.userId}/calendars/advent/${this.state.calendar.id}/${this.makeId(16)}`), media)
                    .then(data => {
                        console.log("[Utils] upload success:", data.ref);
                        this.updateDayContentState(this.state.mediaType, data.ref.fullPath);
                        fromGSUrltoUrl(data.ref.fullPath)
                            .then(url => {
                                this.setState({ fileUrl: url })
                            })
                    })
                    .catch(err => {
                        console.log("[Utils] error uploading file", err);
                    })
            }
        }
    }


    deleteUnusedImages() {
        this.state.uploadedImagesIds.forEach(id => {
            if (this.state.calendar) {
                deleteObject(storageRef(storage, `users-media/${this.state.userId}/calendars/advent/${this.state.calendar.id}/${id}`))
                    .then(response => {
                        console.log("[CalendarEditor] Deleted media from storage");
                    })
                    .catch(e => {
                        console.log('[CalendarEditor] Error deleting media', e);
                    })
            }
        })
    }

    render() {
        return (
            <>
                <IonMenu side="start" menuId="calendarSettingsMenu" contentId='calendarEditorMainContent'>
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>Impostazioni</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent id='calendarEditorMainContent'>
                        <IonList>
                            <div className='calendarSettingsDiv'>
                                <IonTitle>Sfondo dettagliato</IonTitle>
                                <IonItem lines='none'>
                                    <IonLabel style={{ fontSize: '14px' }}>
                                        Visualizza un immagine di sfondo
                                    </IonLabel>
                                    <IonToggle
                                        mode='md'
                                        className='calendarSettingsToggle'
                                        checked={this.state.calendar?.settings.detailedBackground}
                                        onIonChange={(e) => {
                                            if (this.state.calendar) {
                                                const tempCalendar = this.state.calendar;
                                                tempCalendar.settings.detailedBackground = e.detail.checked;
                                                update(ref(database, `calendars/advent/${this.state.userId}/${tempCalendar.id}/s`), {
                                                    b: e.detail.checked,
                                                })
                                                    .then(() => {
                                                        console.log("[CalendarEditor] update background success");
                                                        this.setState({ calendar: tempCalendar });
                                                    })
                                                    .catch(err => {
                                                        console.log("[CalendarEditor] error updating background setting:", err);
                                                    })
                                            }
                                        }}
                                    />
                                </IonItem>
                            </div>

                            <IonItemDivider mode='md' />

                            <div className='calendarSettingsDiv'>
                                <IonTitle>Mostra Caselle CAA</IonTitle>
                                <IonItem lines='none'>
                                    <IonLabel style={{ fontSize: '14px' }}>
                                        Visualizza le caselle in CAA
                                    </IonLabel>
                                    <IonToggle
                                        mode='md'
                                        className='calendarSettingsToggle'
                                        checked={this.state.calendar?.settings.pictogramCardIconVisible}
                                        onIonChange={(e) => {
                                            if (this.state.calendar) {
                                                const tempCalendar = this.state.calendar;
                                                tempCalendar.settings.pictogramCardIconVisible = e.detail.checked;
                                                update(ref(database, `calendars/advent/${this.state.userId}/${tempCalendar.id}/s`), {
                                                    p: e.detail.checked,
                                                })
                                                    .then(() => {
                                                        console.log("[CalendarEditor] update pictogram visible setting success");
                                                        this.setState({ calendar: tempCalendar });
                                                    })
                                                    .catch(err => {
                                                        console.log("[CalendarEditor] error updating pictogram visible setting:", err);
                                                    })
                                            }

                                        }}
                                    />
                                </IonItem>
                            </div>
                            <IonItemDivider mode='md' />
                            <div className='calendarSettingsDiv'>
                                <IonTitle>Audio Caselle CAA</IonTitle>
                                <IonItem lines='none'>
                                    <IonLabel style={{ fontSize: '14px' }}>
                                        Clicca e ascolta giorno e mese
                                    </IonLabel>
                                    <IonToggle
                                        mode='md'
                                        className='calendarSettingsToggle'
                                        checked={this.state.calendar?.settings.soundOnCardTap}
                                        onIonChange={(e) => {
                                            if (this.state.calendar) {
                                                const tempCalendar = this.state.calendar;
                                                tempCalendar.settings.soundOnCardTap = e.detail.checked;
                                                update(ref(database, `calendars/advent/${this.state.userId}/${tempCalendar.id}/s`), {
                                                    s: e.detail.checked,
                                                })
                                                    .then(() => {
                                                        console.log("[CalendarEditor] update sound on card tap success");
                                                        this.setState({ calendar: tempCalendar });
                                                    })
                                                    .catch(err => {
                                                        console.log("[CalendarEditor] error updating sound on card tap setting:", err);
                                                    })
                                            }
                                        }}
                                    />
                                </IonItem>
                            </div>
                        </IonList>

                    </IonContent>
                </IonMenu>
                {
                    this.state.calendar &&
                    <IonPage>
                        <IonHeader>
                            <IonToolbar>
                                <IonTitle className='calendarEditorHeaderTitle'><b>Modifica il tuo CAAlendario</b></IonTitle>
                                <IonButtons slot='start'>
                                    <IonMenuButton menu='calendarSettingsMenu'></IonMenuButton>
                                </IonButtons>
                                <IonButtons slot='end'>
                                    <IonButton
                                        className='dayPopoverButton'
                                        color='primary'
                                        onClick={() => {
                                            this.props.history.push('/home')
                                            //go back
                                        }}>
                                        Salva ed esci
                                    </IonButton>
                                </IonButtons>
                            </IonToolbar>

                        </IonHeader>
                        <IonContent
                            fullscreen
                            className={this.state.calendar?.settings.detailedBackground ? "calendarViewerContent" : ""}
                        >
                            <div className='editorHeaderDiv'>
                                <IonInput className='calendarEditorInput' placeholder='Inserisci un nome' value={this.state.calendar.t} debounce={1000} onIonChange={(e) => this.updateCalendarTitle(e.detail.value!)}></IonInput>
                            </div>
                            <div className='mobileEditorHeaderDiv'>
                                <IonLabel className='mobileEditorHeaderTitle'><b>Modifica il tuo CAAlendario</b></IonLabel>
                                <IonInput style={{ border: '1px solid grey', marginTop: '20px', maxWidth: '200px' }} placeholder='Inserisci un nome' value={this.state.calendar.t} debounce={1000} onIonChange={(e) => this.updateCalendarTitle(e.detail.value!)}></IonInput>
                            </div>

                            {
                                this.state.calendar &&
                                <div className="calendarViewerCardsDiv">
                                    {
                                        this.state.calendar?.c.map((card, i) => {
                                            return (
                                                <div key={i} className="calendarViewerDayCardDiv">
                                                    <div
                                                        className="calendarViewerDayCard"
                                                        style={{ boxShadow: this.state.calendar?.settings.detailedBackground ? "none" : "0px 0px 9px 3px rgba(0,0,0,0.25)" }}
                                                        onClick={() => {
                                                            this.setState({ showDayPopover: true, dayContent: card, dayIndex: i, mediaType: this.getMediaType(card), didEditDay: false })
                                                            console.log("[CalendarEditor] Card:", card);
                                                            if (card.i) {
                                                                fromGSUrltoUrl(card.i)
                                                                    .then(url => {
                                                                        this.setState({ imgUrl: url });
                                                                    })
                                                                    .catch(err => {
                                                                        console.log("[CalendarEditor] error converting gs url:", err);
                                                                    })
                                                            }
                                                            if (card.y) {
                                                                let videoId = card.y.substring(card.y.indexOf('=') + 1);
                                                                this.setState({ videoId: videoId })
                                                            }
                                                            if (card.f) {
                                                                fromGSUrltoUrl(card.f)
                                                                    .then(url => {
                                                                        this.setState({ fileUrl: url })
                                                                        if (!isPlatform("desktop")) {
                                                                            window.open(url, "_blank");
                                                                        }
                                                                    })
                                                            }
                                                        }}
                                                    >
                                                        {
                                                            !this.state.calendar?.settings.pictogramCardIconVisible &&
                                                            <div className="calendarViewerCardTextDiv">
                                                                <p>
                                                                    Giorno
                                                                </p>
                                                                <p>
                                                                    <b>{i + 1}</b>
                                                                </p>
                                                            </div>
                                                        }

                                                        {
                                                            this.state.calendar?.settings.pictogramCardIconVisible &&
                                                            <div style={{ height: "100%", padding: "10px" }}>
                                                                <div style={{ height: "50%", width: "100%", display: 'flex', flexFlow: "row wrap" }}>

                                                                    <img
                                                                        style={{ height: "100%", width: "50%", objectFit: "fill" }}
                                                                        src={fromDayNumberToImg((new Date(card.d * 1000).getDay()))}
                                                                        alt={fromDayNumberToDayName((new Date(card.d * 1000).getDay()))}
                                                                    />
                                                                    <img
                                                                        style={{ height: "100%", width: "50%", objectFit: "fill" }}
                                                                        src={fromDayNumberToDayNumberImage((new Date(card.d * 1000).getDate()))}
                                                                    />

                                                                </div>

                                                                <div style={{ height: "50%", width: "100%", display: 'flex', flexFlow: "row wrap" }}>
                                                                    <img
                                                                        style={{ height: "100%", objectFit: "fill", margin: "0 auto" }}
                                                                        src={fromMonthNumberToMonthImage(12)}
                                                                        alt="Dicembre"
                                                                    />
                                                                </div>
                                                            </div>
                                                        }


                                                        <div className="calendarEditorCardContentTextDiv">
                                                            <span>
                                                                Contenuto:
                                                                {
                                                                    card.t ? ' Testo' :
                                                                        card.y ? ' Video' :
                                                                            card.f ? ' File' :
                                                                                ' Immagine'
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>
                            }
                        </IonContent>

                        <IonPopover
                            backdropDismiss={false}
                            isOpen={this.state.showDayPopover}
                            onDidDismiss={() => {
                                this.setState({ showDayPopover: false })
                            }}
                            cssClass='dayPopover'
                            mode="md"
                        >
                            <IonIcon onClick={() => {
                                if (this.state.didEditDay)
                                    this.setState({ showDiscardAlert: true })
                                else {
                                    if (this.state.uploadedImagesIds.length > 0) {
                                        console.log(this.state.uploadedImagesIds)
                                        this.deleteUnusedImages();
                                    }
                                    this.setState({ showDayPopover: false, imgUrl: '', fileUrl: '' })
                                }
                            }}
                                icon={closeOutline}
                                size='large'
                                style={{ float: 'right', cursor: 'pointer' }} />

                            <h1 className='dayPopoverTitle'>
                                Modifica il Giorno {this.state.dayIndex + 1}
                            </h1>
                            <div className='dayPopoverMainDiv'>
                                <IonGrid className='dayPopoverMainGrid'>
                                    <IonRow className='dayPopoverRow'>
                                        <IonLabel><b>Tipo di contenuto:</b></IonLabel>
                                        <IonSelect
                                            value={this.state.mediaType}
                                            onIonChange={(e) => {
                                                this.setState({ mediaType: e.detail.value, didEditDay: true })
                                            }}
                                        >
                                            <IonSelectOption value='t'>Testo</IonSelectOption>
                                            <IonSelectOption value='f'>File</IonSelectOption>
                                            <IonSelectOption value='i'>Immagine</IonSelectOption>
                                            <IonSelectOption value='y'>Video</IonSelectOption>
                                        </IonSelect>
                                    </IonRow>
                                    <IonRow className='dayPopoverRow'>
                                        <IonLabel><b>Contenuto:</b></IonLabel>
                                    </IonRow>
                                    {
                                        this.state.dayContent &&
                                            this.state.mediaType === 't' ?
                                            (
                                                <IonRow className='dayPopoverRow'>
                                                    <IonTextarea
                                                        style={{ border: 'solid 1px black' }}
                                                        value={this.state.dayContent.t}
                                                        onIonChange={(e) => {
                                                            this.setState({ didEditDay: true })
                                                            this.updateDayContentState(this.state.mediaType, e.detail.value!)
                                                        }}
                                                        debounce={200}
                                                    />
                                                </IonRow>

                                            ) : this.state.mediaType === 'f' ? (
                                                <div
                                                    style={{
                                                        height: this.state.fileUrl ? '30vh' : '5vh',
                                                        display: 'flex',
                                                        flexDirection: 'column'
                                                    }}
                                                >
                                                    <input
                                                        style={{ position: "absolute", top: "-100px" }}
                                                        id="utilsFileInput"
                                                        type={"file"}
                                                        accept="application/pdf"
                                                        onChange={e => {
                                                            this.uploadMedia((e.nativeEvent.target as HTMLInputElement).files?.item(0));
                                                        }}
                                                    />
                                                    <iframe
                                                        hidden={!this.state.fileUrl}
                                                        onContextMenu={(e) => e.preventDefault()}
                                                        src={`${this.state.fileUrl}${this.state.fileUrl ? "#toolbar=0" : ''}`}
                                                        height="100%"
                                                        width="100%"
                                                    />
                                                    <IonRow className='dayPopoverRow'>
                                                        <IonButton
                                                            fill='clear'
                                                            className='dayPopoverButton'
                                                            onClick={() => {
                                                                (document.getElementById("utilsFileInput") as HTMLInputElement).click();
                                                                this.setState({ didEditDay: true })
                                                            }}
                                                        >
                                                            Carica Documento
                                                        </IonButton>
                                                    </IonRow>
                                                </div>

                                            ) : this.state.mediaType === 'i' ? (
                                                <>
                                                    <input
                                                        style={{ position: "absolute", top: "-100px" }}
                                                        id="utilsFileInput"
                                                        type={"file"}
                                                        accept="image/png, image/gif, image/jpeg"
                                                        onChange={e => {
                                                            this.uploadMedia((e.nativeEvent.target as HTMLInputElement).files?.item(0));
                                                        }}
                                                    />
                                                    <img className='dayPopoverImage' src={this.state.imgUrl} alt='Nessuna immagine caricata' onLoad={(e) => e.currentTarget.style.display = 'inline-block'} onError={(e) => (e.currentTarget.style.display = 'none')} />

                                                    <IonRow className='dayPopoverRow'>
                                                        <IonButton
                                                            fill='clear'
                                                            className='dayPopoverButton'
                                                            onClick={() => {
                                                                (document.getElementById("utilsFileInput") as HTMLInputElement).click();
                                                                this.setState({ didEditDay: true })
                                                            }}
                                                        >
                                                            Carica Immagine
                                                        </IonButton>
                                                    </IonRow>
                                                </>

                                            ) : (
                                                this.state.dayContent &&
                                                <>
                                                    <IonRow className="calendarEditorDayPopoverVideoRow">
                                                        <iframe
                                                            src={`https://www.youtube.com/embed/${this.state.videoId}`}
                                                            width="100%"
                                                            height="100%"
                                                            allowFullScreen
                                                        />
                                                    </IonRow>
                                                    <IonRow className='dayPopoverRow'>
                                                        <IonInput style={{ border: 'solid 1px black' }}
                                                            placeholder="Inserisci l'url del video che vuoi caricare (es: https://youtube.com/watch?v=XyZXyZXyZXyZ)"
                                                            value={this.state.dayContent.y}
                                                            onIonChange={(e: any) => {
                                                                var pattern = new RegExp('(?<=watch\\?v=|/videos/|embed\\/|youtu.be\\/|\\/v\\/|\\/e\\/|watch\\?v%3D|watch\\?feature=player_embedded&v=|%2Fvideos%2F|embed%\u200C\u200B2F|youtu.be%2F|%2Fv%2F)[^#\\&\\?\\n]*'); // fragment locator
                                                                if (!pattern.test(e.detail.value))
                                                                    return
                                                                this.setState({ didEditDay: true })
                                                                let videoId = e.detail.value.substring(e.detail.value.indexOf('=') + 1);
                                                                this.setState({ videoId: videoId })
                                                                this.updateDayContentState(this.state.mediaType, e.detail.value);
                                                            }}
                                                            debounce={500}
                                                        />
                                                    </IonRow>
                                                </>
                                            )
                                    }

                                    <IonRow className='dayPopoverRow'>
                                        <IonButton
                                            className='dayPopoverButton'
                                            onClick={() => {
                                                if (!this.state.didEditDay) {
                                                    this.setState({ showDayPopover: false });
                                                    console.log('User did not edit');
                                                }
                                                if (this.isFieldEmpty()) {
                                                    alert('Inserisci il contenuto prima di uscire');
                                                    return;
                                                }
                                                let calendar = this.state.calendar;
                                                calendar!.c[this.state.dayIndex] = this.state.dayContent!
                                                this.setState({ calendar: calendar, didEditDay: false, showDayPopover: false, imgUrl: '', fileUrl: '' })
                                                this.updateCardOnDb();
                                            }}>
                                            Aggiorna
                                        </IonButton>
                                    </IonRow>

                                </IonGrid>
                            </div>
                        </IonPopover>

                        <IonAlert
                            isOpen={this.state.showDiscardAlert}
                            header='Attenzione'
                            message={`Salvare le modifiche al giorno ${this.state.dayIndex + 1} ?`}
                            buttons={[
                                {
                                    text: 'Salva',
                                    handler: () => {
                                        if (this.isFieldEmpty()) {
                                            alert('Inserisci il contenuto prima di uscire');
                                            this.setState({ showDiscardAlert: false })
                                            return;
                                        }
                                        let calendar = this.state.calendar;
                                        calendar!.c[this.state.dayIndex] = this.state.dayContent!
                                        this.setState({ calendar: calendar }, () => {
                                            console.log(this.state.calendar!.c[this.state.dayIndex])
                                        })
                                        this.updateCardOnDb();
                                        this.setState({ showDayPopover: false, showDiscardAlert: false, didEditDay: false, imgUrl: '', fileUrl: '' })
                                    }
                                },
                                {
                                    text: 'Non salvare',
                                    handler: () => {
                                        this.setState({ showDayPopover: false, showDiscardAlert: false, didEditDay: false, imgUrl: '', fileUrl: '' })
                                        if (this.state.uploadedImagesIds.length > 0) {
                                            this.deleteUnusedImages();
                                        }
                                    }
                                },
                                {
                                    text: 'Annulla',
                                    handler: () => {
                                        this.setState({ showDiscardAlert: false })
                                        return
                                    }
                                }
                            ]}
                        />
                    </IonPage>
                }
            </>

        );
    }

}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarEditor);