import {
    IonButton, IonContent, IonInput, IonPage,
} from '@ionic/react';
import React from 'react';
import { sendSignInLinkToEmail } from "firebase/auth";
import { connect } from 'react-redux';
import { auth } from '../../../common/firebase';
import caalendarioScritta from "../../../assets/images/caalendarioScritta.png";
import { isEmailValid } from '../../../common/utils';
import handGloveGift from "../../../assets/images/handGloveGift.png";
import "./LandingPage.css";

type Props = {
    history: any,

    logout: () => void,
};

type State = {
    email: string
    emailSent: boolean,
};

class LandingPage extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            email: "",
            emailSent: false,
        }
    }

    componentDidMount() {
        auth.onAuthStateChanged(userData => {
            if (userData) {
                this.props.history.replace("/home")
            }
        })
    }

    render() {
        return (
            <IonPage>
                <IonContent className="landingPageContent">

                    <div
                        id="landingPageContentDiv"
                        className="landingPageContentDiv"
                    >
                        <div
                            className="landingPageLogoImageDiv"
                            onClick={() => {
                                const landingPageContentDiv = document.getElementById("landingPageContentDiv") as HTMLDivElement
                                if (landingPageContentDiv) {
                                    landingPageContentDiv.scrollIntoView({ behavior: "smooth", block: "end" })
                                }
                            }}
                        >
                            <img
                                className="landingPageLogoImage"
                                src={caalendarioScritta}
                                alt="Logo Calendario dell'Avvento"
                            />
                        </div>


                        <div className="landingPagePlayerDiv">
                            <iframe
                                className="landingPagePlayerFrame"
                                frameBorder="0"
                                scrolling="no"
                                marginHeight={0}
                                marginWidth={0}
                                allowFullScreen
                                src="https://www.youtube-nocookie.com/embed/AXrhADx3Lm0?autoplay=0&fs=1&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&vq=hd1080"
                            />
                        </div>

                        <div className="landingPageSubtitleDiv">
                            <p className="landingPageSubtitleParagraph">
                                Personalizzabile<br />
                                Accessibile<br />
                                +<br />
                                regalo di Natale!
                            </p>
                        </div>

                        {
                            !this.state.emailSent &&
                            <div className="landingPageFeaturesDiv">
                                <div className="landingPageFeatureDiv">
                                    <div className="landingPageFeatureTextDiv">
                                        <p className="landingPageFeaturesTitle">
                                            Personalizzabile
                                        </p>
                                        <ul>
                                            <li>
                                                <b>Crea più calendari</b> e <b>condividili</b> con chi vuoi
                                            </li>
                                            <li>
                                                Varie <b>attività in CAA già presenti</b>
                                            </li>
                                            <li>
                                                <b>Modifica</b> caricando <b>immagini</b>, <b>video</b>, <b>pdf</b> o <b>scrivi un testo per ogni giorno</b>.
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="landingPageFeatureDiv">
                                    <div className="landingPageFeatureTextDiv">
                                        <p className="landingPageFeaturesTitle">
                                            Accessibile
                                        </p>
                                        <ul>
                                            <li><b>Pittogrammi in CAA</b> del numero, giorno della settimana e mese</li>
                                            <li><b>Clicca e Ascolta</b>: voce di Babbo Natale che annuncia la data del calendario</li>
                                            <li><b>Sfondo ad alto contrasto</b></li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="landingPageGiftDiv">
                                    {/* <p>
                                        +<br/>
                                        Regalo di Natale!
                                    </p> */}
                                    <p>
                                        +
                                        Regalo di Natale!
                                    </p>
                                </div>

                                <div className="landingPageGiftDescriptionDiv">
                                    <p>
                                        <b>Completa il CAAlendario</b> insieme a tuo figlio o ad un bambino/ragazzo con cui lavori. <b>A Natale c'è una sorpresa divertente per lui!</b>
                                    </p>

                                    <p>
                                        <b>Perché la magia dell'attesa del Natale può e deve essere accessibile per tutti i bambini e ragazzi con disabilità.</b>
                                    </p>
                                </div>
                            </div>
                        }

                        {
                            !this.state.emailSent &&
                            <div className="landingPageCTADiv">
                                <p className="landingPageCTAParagraph">
                                    Registrati gratuitamente al per accedere subito al CAAlendario dell'Avvento&nbsp;
                                    <a
                                        href="https://estro.ai/?utm_source=website&utm_medium=banner&utm_campaign=CAAlendario&utm_id=CAAlendario"
                                        target="_blank"
                                    >
                                        creato da Estro
                                    </a>
                                </p>
                                <div className="landingPageEmailDiv">

                                    <IonInput
                                        className="landingPageEmailInput"
                                        autocomplete="email"
                                        placeholder="Email"
                                        value={this.state.email}
                                        onIonChange={e => {
                                            this.setState({ email: e.detail.value ? e.detail.value : "" });
                                        }}
                                    />

                                    <IonButton
                                        disabled={!isEmailValid(this.state.email)}
                                        className="landingPageEmailButton"
                                        onClick={() => {
                                            const actionCodeSettings = {
                                                /* url: 'http://localhost:8100/authentication/completion', */
                                                url: 'https://caalendario.it/authentication/completion',
                                                handleCodeInApp: true,
                                            };

                                            sendSignInLinkToEmail(auth, this.state.email, actionCodeSettings)
                                                .then(() => {
                                                    console.log("[LandingPage] Success. Email sent");
                                                    window.localStorage.setItem('emailForSignIn', this.state.email);
                                                    this.setState({ emailSent: true });
                                                })
                                                .catch((error) => {
                                                    const errorCode = error.code;
                                                    const errorMessage = error.message;
                                                    console.log("[LandingPage] error sending magic link:", error);
                                                });
                                        }}
                                    >
                                        Inizia subito
                                    </IonButton>
                                </div>
                            </div>
                        }

                        {
                            this.state.emailSent &&
                            <div className="landingPageEmailSentDiv">
                                <div className="landingPageEmailSentImgDiv">
                                    <img
                                        className="landingPageEmailSentImg"
                                        src={handGloveGift}
                                    />
                                </div>

                                <p className="landingPageEmailSentParagraph">
                                    Registrazione completata!<br /><br />

                                    Ora corri a dare un'occhiata alla tua casella di posta, <b>ti abbiamo appena inviato la email di accesso</b>.<br /><br />

                                    PS: se usi Gmail cercala nella cartella Spam oppure Promozioni e trascinala nella sezione Principale, altrimenti rischi di perderti le successive ;-)<br /><br />

                                    Ci vediamo dall'altra parte!
                                </p>

                            </div>
                        }
                    </div>

                    <div className="landingPageCreditsDiv">
                        <p
                            onClick={() => {
                                window.open("https://estro.ai/?utm_source=website&utm_medium=banner&utm_campaign=CAAlendario&utm_id=CAAlendario")
                            }}
                        >
                            <b>Estro technologies srl</b><br />
                            Via Savona, 123 – 20144 Milano (Italia)<br />
                            C.F./P.IVA: IT10964560964<br />
                            Camera di Commercio di Milano<br />
                            CS: 1000 Eur (I.V.)
                        </p>
                    </div>


                </IonContent>
            </IonPage>

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);