import { settingsConstants } from './constants';

export interface settingsStore {
    showColors: boolean,
    categoryFilter: string[],
    unmutePressed: boolean,
}

const initialState: settingsStore = {
    showColors: true,
    categoryFilter: ['objects', 'food', 'animals'],
    unmutePressed: false,
}

const Settings = (state = initialState, action: any) => {
    switch (action.type) {

        case settingsConstants.GRAYSCALE_IMAGES:
            return {
                ...state,
                showColors: action.color,
            }
        case settingsConstants.CHANGE_FILTER:
            return {
                ...state,
                categoryFilter: action.filter,
            }

        case settingsConstants.UNMUTE_PRESSED:
            return {
              ...state,
              unmutePressed: action.pressed
            }

        default: {
            return state
        }

    }
}

export default Settings;