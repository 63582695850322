import december1Voice from "../../../assets/tts/it_it/december1.mp3"; 
import december2Voice from "../../../assets/tts/it_it/december2.mp3";
import december3Voice from "../../../assets/tts/it_it/december3.mp3";
import december4Voice from "../../../assets/tts/it_it/december4.mp3";
import december5Voice from "../../../assets/tts/it_it/december5.mp3";
import december6Voice from "../../../assets/tts/it_it/december6.mp3";
import december7Voice from "../../../assets/tts/it_it/december7.mp3";
import december8Voice from "../../../assets/tts/it_it/december8.mp3";
import december9Voice from "../../../assets/tts/it_it/december9.mp3";
import december10Voice from "../../../assets/tts/it_it/december10.mp3";
import december11Voice from "../../../assets/tts/it_it/december11.mp3";
import december12Voice from "../../../assets/tts/it_it/december12.mp3";
import december13Voice from "../../../assets/tts/it_it/december13.mp3";
import december14Voice from "../../../assets/tts/it_it/december14.mp3";
import december15Voice from "../../../assets/tts/it_it/december15.mp3";
import december16Voice from "../../../assets/tts/it_it/december16.mp3";
import december17Voice from "../../../assets/tts/it_it/december17.mp3";
import december18Voice from "../../../assets/tts/it_it/december18.mp3";
import december19Voice from "../../../assets/tts/it_it/december19.mp3";
import december20Voice from "../../../assets/tts/it_it/december20.mp3";
import december21Voice from "../../../assets/tts/it_it/december21.mp3";
import december22Voice from "../../../assets/tts/it_it/december22.mp3";
import december23Voice from "../../../assets/tts/it_it/december23.mp3";
import december24Voice from "../../../assets/tts/it_it/december24.mp3";
import december25Voice from "../../../assets/tts/it_it/december25.mp3";

export const itVoices = [
    december1Voice, december2Voice, december3Voice, december4Voice, december5Voice,
    december6Voice, december7Voice, december8Voice, december9Voice, december10Voice,
    december11Voice, december12Voice, december13Voice, december14Voice, december15Voice,
    december16Voice, december17Voice, december18Voice, december19Voice, december20Voice,
    december21Voice, december22Voice, december23Voice, december24Voice, december25Voice
]