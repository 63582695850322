import {
    IonBackButton,
    IonButtons,
    IonContent, IonHeader, IonPage, IonTitle, IonToolbar,
} from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';

type Props = {
    history: any,
};

type State = {
};

class Support extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }


    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Supporto</IonTitle>
                        <IonButtons slot='start'>
                            <IonBackButton defaultHref="/home" />
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <div>
                        <p>
                            Se avessi domande, contattaci al seguente indirizzo: <a href="mailto:info@caalendario.it">info@caalendario.it</a>.
                        </p>
                    </div>
                    <div>
                        <h2>Licenze</h2>
                        <p>
                            The pictographic symbols used are the property of the Government of Aragón and have been created by Sergio Palao for ARASAAC (http://www.arasaac.org), that distributes them under Creative Commons License BY-NC-SA. <br/>
                            Pictograms author: Sergio Palao. Origin: ARASAAC (http://www.arasaac.org). License: CC (BY-NC-SA). Owner: Government of Aragon (Spain).
                        </p>
                    </div>
                </IonContent>
            </IonPage>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Support);