import React from 'react';
import "./Popovers.css";

type Props = {
};

type State = {
    showLeaveInAppBrowser: boolean,
    showTopFinger: boolean,
    showBottomFinger: boolean,
};

class Popovers extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            showLeaveInAppBrowser: false,
            showTopFinger: false,
            showBottomFinger: false,
        }
    }

    componentDidMount() {
        const userAgent = window.navigator.userAgent.toLowerCase(),
            safari = /safari/.test(userAgent),
            ios = /iphone|ipod|ipad/.test(userAgent);

        if (ios) {
            if (safari) {
                this.setState({ showLeaveInAppBrowser: false });
            } else if (!safari) {
                this.setState({ showLeaveInAppBrowser: true });

                var ua = navigator.userAgent || navigator.vendor; // || window.opera;
                var isInstagram = (ua.indexOf('Instagram') > -1) ? true : false;
                if(isInstagram) {
                    this.setState({ showTopFinger: true });
                }
                else {
                    this.setState({ showBottomFinger: true });
                }
            };
        } else {
            this.setState({ showLeaveInAppBrowser: false })
        };
    }

    render() {
        return (
            <>
                {
                    this.state.showLeaveInAppBrowser &&
                    <div className="popoversLeaveInAppBrowserDiv">
                        <div className="popoversLeaveInAppBrowserInnerDiv">
                            <p>
                                Sembra che tu stia utilizando CAAlendario da un browser dentro un'app come Gmail, Facebook o Messenger.
                            </p>
                            <p>
                                <b>Clicca sull'icona {`${this.state.showTopFinger ? "in alto a destra" : "in basso a destra"}`}</b> e poi "<b>Apri in Safari</b>" per utilizzare il CAAlendario senza problemi.
                            </p>
                            {/* <p>
                                {navigator.userAgent}
                            </p> */}
                        </div>

                        {
                            this.state.showTopFinger &&
                            <p className="popoversLeaveInAppBrowserTopFingerParagraph">
                                👆
                            </p>
                        }

                        {
                            this.state.showBottomFinger &&
                            <p className="popoversLeaveInAppBrowserBottomFingerParagraph">
                                👇
                            </p>
                        }
                    </div>
                }
            </>
        );
    }
}


export default Popovers;