import { child, child as dbChildRef, equalTo, get, orderByChild, push, query, ref as databaseRef, remove, update } from '@firebase/database';
import { isPlatform } from '@ionic/core';
import { IonAlert, IonButton, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonPopover, IonRow, IonToolbar } from '@ionic/react';
import { deleteUser, signOut } from 'firebase/auth';
import { ref } from 'firebase/database';
import { deleteObject, listAll, ref as storageRef } from 'firebase/storage';
import { closeOutline, createOutline, giftOutline, helpCircleOutline, pencilOutline, personOutline, trash } from 'ionicons/icons';
import React from 'react';
import { connect } from 'react-redux';
import homeTitle from '../../../assets/images/caalendario.svg';
import gift from '../../../assets/lotties/gift.gif';
import { auth, database, storage } from '../../../common/firebase';
import { Calendar, fromCalendarDbStructureToCalendar } from '../../Calendar/utils/calendarUtils';
import "./Home.css";


type Props = {
    history: any,
};

type State = {
    isLoading: boolean,

    userId: string | null,
    calendars: Calendar[],
    showDeleteCalendarAlert: boolean,
    selectedCalendar: Calendar | undefined,
    showRenameCalendar: boolean,
    tempTitle: string,
    canCollectReward: boolean,
    showRewardPopover: boolean,
};

class Home extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: false,

            userId: null,
            calendars: [],
            showDeleteCalendarAlert: false,
            selectedCalendar: undefined,
            showRenameCalendar: false,
            tempTitle: '',
            canCollectReward: false,
            showRewardPopover: false,
        }
    }

    componentDidMount() {
        //GET USER'S CALENDARS
        auth.onAuthStateChanged(userData => {
            if (userData) {
                this.setState({ userId: userData.uid });
                get(child(databaseRef(database), `users/${userData.uid}`)).then((snapshot) => {
                    if (snapshot.exists()) {
                        //GETS THE R FIELD, if R exists then user is eligible for reward
                        let result = snapshot.val();
                        //console.log(result.r)
                        this.setState({ canCollectReward: result.r, showRewardPopover: true })
                    }
                    else {
                        console.log("No data available");
                    }
                })
                    .catch((error) => {
                        console.error(error);
                    });

                this.getUserCalendars(userData.uid);
            }
            else {
                this.setState({ userId: null })
            }
        })
        let now = new Date();
        // console.log(now.getMonth())
        if (now.getMonth() == 11 && now.getDate() == 25) {
            document.getElementsByClassName('countdownTextTop')[0].innerHTML = ''
            document.getElementsByClassName('countdownTextBottom')[0].innerHTML = ''
            document.getElementById("countdown")!.innerHTML = "\
            <p class=\"countdownTextTop\">Oggi è Natale!</p>\
            <p>Tanti auguri dal team Estro</p>";
        }

        else
            this.updateCountdown();


    }
    componentWillUnmount() {
        var interval_id = window.setInterval(() => { }, 99999);
        for (var i = 0; i < interval_id; i++)
            window.clearInterval(i);
    }

    updateCountdown() {
        // Set the date we're counting down to
        let currentYear = new Date().getFullYear()
        var countDownDate = new Date(`Dec 25, ${currentYear} 00:00:00`).getTime();
        var x = setInterval(function () {

            // Get today's date and time
            var now = new Date().getTime();

            // Find the distance between now and the count down date
            var distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            // Display the result in the element with id="demo"
            document.getElementById("countdown")!.innerHTML = "<div class=\"days\"> \
            <div class=\"numbers\">" + days + "</div>giorni</div> \
          <div class=\"hours\"> \
            <div class=\"numbers\">" + hours + "</div>ore</div> \
          <div class=\"minutes\"> \
            <div class=\"numbers\">" + minutes + "</div>minuti</div> \
          <div class=\"seconds\"> \
            <div class=\"numbers\">" + seconds + "</div>secondi</div> \
          </div>";

            // If the count down is finished, write some text
            if (distance < 0) {
                clearInterval(x);
                document.getElementsByClassName('countdownTextTop')[0].innerHTML = ''
                document.getElementsByClassName('countdownTextBottom')[0].innerHTML = ''
            }
        }, 1000);
    }

    getUserCalendars(userUuid: string) {
        get(child(databaseRef(database), `calendars/advent/${userUuid}`))
            .then((snapshot) => {
                if (snapshot.exists()) {
                    const tempCalendars: Calendar[] = [];
                    const calendarsIds = Object.keys(snapshot.val());

                    for (let i = 0; i < calendarsIds.length; i++) {
                        tempCalendars.push(fromCalendarDbStructureToCalendar(calendarsIds[i], snapshot.val()[calendarsIds[i]]));
                    }
                    this.setState({ calendars: tempCalendars });
                }
                else {
                    console.log("[Home] No user's calendars");
                    this.setState({ calendars: [] });
                }
            })
            .catch(err => {
                console.log("[Home] error getting user's calendars:", err);
            })
    }

    updateCalendarTitle(newTitle: string | null | undefined, calendar: Calendar | undefined) {
        if (newTitle === '')
            newTitle = 'Inserisci un titolo calendario'
        if (newTitle && calendar) {
            update(ref(database, `/calendars/advent/${this.state.userId}/${calendar.id}/`), { t: newTitle })
            window.location.reload()
        }
    }

    render() {
        return (
            <div style={{ backgroundColor: 'none', height: '100%' }}>
                <IonHeader>
                    <IonToolbar>
                        <img src={homeTitle} width='250px' style={{ marginLeft: '15px' }} />
                        <IonButton
                            slot="end"
                            color="medium"
                            fill="clear"
                            onClick={() => {
                                this.setState({ showRewardPopover: true })
                            }}>
                            <IonIcon
                                size="large"
                                hidden={!this.state.canCollectReward}
                                className='homeHeaderGiftIcon'
                                icon={giftOutline}
                            />
                        </IonButton>
                        <IonButton
                            slot="end"
                            color="medium"
                            routerLink="/support"
                            fill="clear"
                        >
                            <IonIcon
                                size="large"
                                icon={helpCircleOutline}
                            />

                        </IonButton>
                        <IonButton
                            slot="end"
                            color="medium"
                            routerLink="/profile"
                            fill="clear"
                        >
                            <IonIcon
                                size="large"
                                icon={personOutline}
                            />

                        </IonButton>
                    </IonToolbar>

                </IonHeader>
                <IonContent className='homeMainDiv'>
                    {
                        this.state.calendars.length > 0 &&
                        <div className="homeMenuDiv">
                            <IonList className='homeCalendarList'>

                                {
                                    this.state.calendars.map((calendar) => {
                                        return (
                                            <IonItem key={calendar.id}>
                                                <IonGrid className='homeMainGrid'>
                                                    <IonButton
                                                        fill="clear"
                                                        color="medium"
                                                        onClick={() => {
                                                            this.setState({ showDeleteCalendarAlert: true, selectedCalendar: calendar })
                                                        }}
                                                    >
                                                        <IonIcon
                                                            icon={trash}
                                                            size="small"
                                                        />
                                                    </IonButton>

                                                    <IonRow
                                                        className='homeCalendarTitle'
                                                        onClick={() => {
                                                            this.props.history.push({
                                                                pathname: `/calendar/advent/${calendar.id}`,
                                                            });
                                                        }}
                                                    >
                                                        {calendar.t}
                                                        {/*     <IonInput
                                                                value={calendar.t}
                                                                placeholder='Inserisci un titolo'
                                                                style={{ maxWidth: '200px' }}
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                }}
                                                                onIonChange={(e) => { this.updateCalendarTitle(e.detail.value!, calendar) }}
                                                                debounce={1000}
                                                            / */}

                                                    </IonRow>
                                                    <IonRow
                                                        className='homeCalendarPlayRow'
                                                        onClick={() => {
                                                            this.setState({ showRenameCalendar: true, selectedCalendar: calendar, tempTitle: calendar.t })
                                                        }}
                                                    >
                                                        <IonButton
                                                            fill="clear"
                                                        >
                                                            <IonIcon
                                                                icon={pencilOutline}
                                                                size="large"
                                                            />
                                                        </IonButton>
                                                        <p className="homeCalendarButtonLabelParagraph">
                                                            Rinomina {/* TO LOCALIZE THIS */}
                                                        </p>
                                                    </IonRow>
                                                    <IonRow
                                                        className='homeCalendarPlayRow'
                                                        onClick={() => {
                                                            this.props.history.push({
                                                                pathname: "/calendar-editor",
                                                                state: {
                                                                    calendar: calendar,
                                                                    userId: this.state.userId
                                                                },
                                                            });
                                                        }}
                                                    >
                                                        <IonButton
                                                            fill="clear"
                                                        >
                                                            <IonIcon
                                                                icon={createOutline}
                                                                size="large"
                                                            />
                                                        </IonButton>
                                                        <p className="homeCalendarButtonLabelParagraph">
                                                            Modifica {/* TO LOCALIZE THIS */}
                                                        </p>
                                                    </IonRow>

                                                </IonGrid>
                                            </IonItem>
                                        )
                                    })}


                                {/* CON HTTP E DA DESKTOP NON FUNZIONA, È NORMALE */}
                                {
                                    !isPlatform("desktop") &&
                                    <IonButton
                                        className="homeMenuShareButton"
                                        fill="clear"
                                        onClick={() => {
                                            navigator.share({
                                                title: "CAAlendario",
                                                text: "Prova anche tu il CAAlendario",
                                                url: "https://caalendario.it"
                                            })
                                        }}
                                    >
                                        Condividi l'app {/* TO LOCALIZE THIS */}
                                    </IonButton>
                                }

                            </IonList>
                        </div>
                    }

                    <div className="homeCreateNewCalendarButtonOuterDiv">
                        <div
                            className="homeCreateNewCalendarButtonInnerDiv"
                            onClick={() => {
                                this.setState({ isLoading: true });
                                get(databaseRef(database, `calendars/templates/-MpB_aZBPsWsMsqM3TiA/`))
                                    .then((data) => {
                                        if (data.val()) {
                                            const nowTimestamp = Math.floor((new Date()).getTime() / 1000);
                                            const dataToWrite = data.val();
                                            dataToWrite.t = this.caalendarioNameGenerator();
                                            dataToWrite.r = nowTimestamp;
                                            push(databaseRef(database, `calendars/advent/${this.state.userId}`), dataToWrite)
                                                .then(data2 => {

                                                    push(databaseRef(database, `calendars/relations`), {
                                                        c: data2.key,
                                                        u: this.state.userId,
                                                    })
                                                        .then(() => {
                                                            console.log("[Home] copy created:", data2);
                                                            this.setState({ isLoading: false }, () => {
                                                                if (data2.key) {
                                                                    this.props.history.push({
                                                                        pathname: "/calendar-editor",
                                                                        state: {
                                                                            calendar: fromCalendarDbStructureToCalendar(data2.key, dataToWrite),
                                                                            userId: this.state.userId
                                                                        },
                                                                    });
                                                                }
                                                            });
                                                        })
                                                        .catch(err3 => {
                                                            console.log("[Home] error pushing relation:", err3);
                                                        });


                                                })
                                                .catch(err2 => {
                                                    console.log("[Home] error writing calendar copy:", err2)
                                                    this.setState({ isLoading: false });
                                                });
                                        }
                                    })
                                    .catch(err1 => {
                                        console.log("[Home] error getting advent calendar template:", err1);
                                        this.setState({ isLoading: false });
                                    });
                            }}
                        >
                            <a className="homeCreateNewCalendarButton">
                                Crea nuovo calendario
                            </a>
                        </div>
                        <div className="landingPageCreditsDiv">
                            <p
                                onClick={() => {
                                    window.open("https://estro.ai/?utm_source=website&utm_medium=banner&utm_campaign=CAAlendario&utm_id=CAAlendario")
                                }}
                            >
                                <b>Creato da Estro</b>
                            </p>
                        </div>
                    </div>
                    <p className='countdownTextTop'>Mancano</p>
                    <p id="countdown"></p>
                    <p className='countdownTextBottom'>a Natale!</p>
                </IonContent>

                <IonAlert
                    isOpen={this.state.showDeleteCalendarAlert}
                    header='Attenzione'
                    message={`Vuoi davvero rimuovere il caalendario?`}
                    buttons={[
                        {
                            text: 'Rimuovi',
                            handler: () => {
                                this.setState({ isLoading: true });
                                remove(databaseRef(database, `calendars/advent/${this.state.userId}/${this.state.selectedCalendar!.id}`))
                                    .then(() => {
                                        console.log("[Home] success removing calendar");
                                        const relationsRef = databaseRef(database, `calendars/relations`);
                                        get(query(relationsRef, orderByChild("c"), equalTo(this.state.selectedCalendar!.id)))
                                            .then(async data => {
                                                console.log("[Home] queried data:", data.val());
                                                if (data.exists()) {
                                                    const relationsKeys = Object.keys(data.val());
                                                    for (let i = 0; i < relationsKeys.length; i++) {
                                                        try {
                                                            await remove(dbChildRef(relationsRef, relationsKeys[i]))
                                                            console.log("[Home] relation id:", relationsKeys[i], "removed");
                                                        }
                                                        catch (e) {
                                                            console.log("[Home] error removing relation id:", relationsKeys[i]);
                                                        }
                                                    }
                                                    this.setState({ isLoading: false });
                                                    if (this.state.userId) {
                                                        this.getUserCalendars(this.state.userId);
                                                    }
                                                }
                                            })
                                            .catch(err2 => {
                                                console.log("[Home] error getting relations for calendar id:", this.state.selectedCalendar!.id, "error:", err2);
                                                this.setState({ isLoading: false });
                                            });
                                        this.state.selectedCalendar?.c.forEach((day) => {
                                            if (day.f) {
                                                if (day.f.includes('gs://estro-caalendario.appspot.com/templates/')) //check if media is a template (not loaded in users-media)
                                                    return
                                                deleteObject(storageRef(storage, day.f))
                                                    .then(response => {
                                                        console.log("Deleted related files", response);
                                                    })
                                            }
                                            else if (day.i) {
                                                if (day.i.includes('gs://estro-caalendario.appspot.com/templates/')) //check if media is a template (not loaded in users-media)
                                                    return
                                                deleteObject(storageRef(storage, day.i))
                                                    .then(response => {
                                                        console.log("Deleted related images", response);
                                                    })
                                            }
                                        })

                                    })

                                    .catch(err => {
                                        this.setState({ isLoading: false });
                                        console.log("[Home] Error removing calendar id:", this.state.selectedCalendar!.id, "error:", err)
                                    });
                                this.setState({ showDeleteCalendarAlert: false })
                            }
                        },
                        {
                            text: 'Annulla',
                            handler: () => {
                                this.setState({ showDeleteCalendarAlert: false })
                                return
                            }
                        }
                    ]}
                />
                <IonPopover
                    isOpen={this.state.canCollectReward && this.state.showRewardPopover}
                    cssClass='calendarRewardPopover'
                    onDidDismiss={() => { this.setState({ showRewardPopover: false }) }}
                >
                    <IonIcon onClick={() => {
                        this.setState({ showRewardPopover: false })
                    }}
                        icon={closeOutline}
                        size='large'
                        style={{ float: 'right', cursor: 'pointer' }} />
                    <h1 className='calendarRewardPopoverTitle'>
                        Scarta il tuo regalo
                    </h1>
                    <div className='calendarRewardPopoverDiv'>

                        <img src={gift} style={{ width: '200px' }} />
                        <div className='calendarRewardPopoverTextDiv'>
                            <IonLabel>Ora puoi accedere alla tua ricompensa,
                                clicca il pulsante qui sotto per ottenerla subito
                            </IonLabel>

                        </div>
                        <IonButton
                            className='calendarRewardPopoverButton'
                            onClick={() => {
                                window.location.replace('https://app.gedu.it/caalendario-2021');
                            }}>
                            Scarta il regalo!
                        </IonButton>
                    </div>
                </IonPopover>
                <IonPopover
                    isOpen={this.state.showRenameCalendar}
                    onDidDismiss={() => {
                        this.setState({ showRenameCalendar: false })
                    }}
                    cssClass='calendarRenamePopover'
                >
                    <IonIcon onClick={() => {
                        this.setState({ showRenameCalendar: false, tempTitle: '' })
                    }}
                        icon={closeOutline}
                        size='large'
                        style={{ float: 'right', cursor: 'pointer' }} />
                    <h1 className='calendarRenamePopoverTitle'>
                        Rinomina il tuo caalendario
                    </h1>
                    <div className='calendarRenamePopoverDiv'>
                        <IonInput
                            className='calendarRenamePopoverInput'
                            onIonChange={(e) => this.setState({ tempTitle: e.detail.value! })}
                            value={this.state.tempTitle}
                            placeholder='Inserisci un nome per il caalendario'
                        />
                        <IonButton
                            className='calendarRenamePopoverButton'
                            onClick={(e) => {
                                this.updateCalendarTitle(this.state.tempTitle, this.state.selectedCalendar)
                                this.setState({ tempTitle: '', showRenameCalendar: false })
                            }}>
                            Aggiorna titolo
                        </IonButton>
                    </div>
                </IonPopover>

                <IonLoading
                    isOpen={this.state.isLoading}
                    onDidDismiss={() => {
                        this.setState({ isLoading: false });
                    }}
                    message={"Attendere prego..."} /* TO LOCALIZE THIS */
                    duration={10000}
                />

            </div>
        );
    }

    caalendarioNameGenerator() {
        const aggettivi: string[] = ["stupendo", "divertente", "fantastico", "pazzesco", "strepitoso", "sorprendente", "furbo", "incredibile", "fortissimo", "geniale", "🗓", "🤩", "🎅", "🎄", "mitico"];
        return `Calendario ${aggettivi[Math.floor(Math.random() * aggettivi.length)]}`;
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);