import { Redirect, Route, Switch } from 'react-router-dom';
import { IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { store } from './common/store';
import LandingPage from './modules/LandingPage/pages/LandingPage';
import AuthenticationCompletion from './modules/Authentication/pages/AuthenticationCompletion';
import Home from './modules/Home/pages/Home';
import CalendarViewer from './modules/Calendar/CalendarViewer';
import CalendarEditor from './modules/Calendar/CalendarEditor';
import Support from './modules/Support/pages/Support';
import Profile from './modules/Profile/pages/Profile';

function PrivateRoute({ ...rest }: any) {
    let isAuthenticated = store.getState().auth.userData ? true : false;
    console.log("isAuthenticated", isAuthenticated);
    return (
        <Route
            {...rest}
            render={isAuthenticated ? rest['render'] : () => <Redirect to="/landing-page" />}
        />
    );
}

function Router() {
    return (
        <IonReactRouter>
            <IonRouterOutlet>
                <Switch>
                    <Route exact path="/landing-page" component={LandingPage} />
                    <Route exact path="/authentication/completion" component={AuthenticationCompletion} />
                    <Route exact path="/support" component={Support} />

                    <PrivateRoute
                        exact path="/home"
                        render={(routeProps: any) => <Home  {...routeProps} />}
                    />
                    <PrivateRoute
                        exact path="/profile"
                        render={(routeProps: any) => <Profile  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/calendar-viewer"
                        render={(routeProps: any) => <CalendarViewer {...routeProps} />}
                    />

                    <Route
                        exact path="/calendar/advent/:calendarId"
                        render={(routeProps: any) => <CalendarViewer {...routeProps} />}
                    />
                    <PrivateRoute
                        exact path="/calendar-editor"
                        render={(routeProps: any) => <CalendarEditor {...routeProps} />}
                    />


                    <Redirect to="/home" />
                    {/* <Redirect to="/" /> */}

                </Switch>
            </IonRouterOutlet>
        </IonReactRouter>
    );
}

export default Router;