import {
    IonContent, IonTitle, IonPage, IonToolbar, IonHeader,
    IonPopover, IonRow, IonButtons, IonBackButton, IonInput,
    IonLabel, IonIcon, IonButton,
} from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import { auth, database, functions } from '../../common/firebase';
import { sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink } from '@firebase/auth';
import { get, ref, update, query, equalTo, orderByChild, push } from '@firebase/database';
import {
    Calendar, Card, fromCalendarDbStructureToCalendar, fromDayNumberToDayName,
    fromDayNumberToDayNumberImage, fromDayNumberToImg, fromGSUrltoUrl, fromMonthNumberToMonthImage
} from '../Calendar/utils/calendarUtils';
import { itVoices } from './utils/calendarTts';
import { copy, notifications, share } from 'ionicons/icons';
import { settingsActions } from '../Settings/store/actions';
import { isPlatform } from '@ionic/core';
import { httpsCallable } from 'firebase/functions';
import "./CalendarViewer.css";

type Props = {
    match: any,
    history: any,

    unmutePressed: boolean,
    unmute: (pressed: boolean) => void,
};

type State = {
    currentMonth: number,
    currentDay: number,

    calendar: Calendar | null,
    userId: string | null,
    showDayPopover: boolean,
    dayContent: Card | null,
    dayIndex: number,

    email: string,
    signInEmailSent: boolean,
    showVerifyEmailDiv: boolean,
    mediaType: string,
    videoId: string,
    imgUrl: string,
    fileUrl: string,

    showCalendarNotFound: boolean,

    showSharePopover: boolean,
    showTooSoonPopover: boolean,
};

class CalendarViewer extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            currentMonth: 0,
            currentDay: 0,

            calendar: null,
            userId: null,
            showDayPopover: false,
            dayContent: null,
            dayIndex: 0,

            email: "",
            signInEmailSent: false,
            showVerifyEmailDiv: false,
            mediaType: '',
            videoId: '',
            imgUrl: '',
            fileUrl: '',

            showCalendarNotFound: false,

            showSharePopover: false,
            showTooSoonPopover: false,
        }
    }

    componentDidMount() {
        auth.onAuthStateChanged(userData => {
            if (userData) {
                this.setState({ userId: userData.uid });
                const dbRef = ref(database, `calendars/relations`);
                get(query(dbRef, orderByChild("c"), equalTo(this.props.match.params.calendarId)))
                    .then(data => {
                        console.log("[CalendarViewer] queried data:", data.val());
                        if (data.exists()) {
                            const relationsKeys = Object.keys(data.val());
                            if (relationsKeys.length === 1) {
                                //console.log("Creator id:", data.val()[relationsKeys[0]].u);
                                const creatorId = data.val()[relationsKeys[0]].u;
                                get(ref(database, `calendars/advent/${creatorId}/${this.props.match.params.calendarId}`))
                                    .then(calendarData => {
                                        console.log("[CalendarViewer] calendar data:", calendarData.val());

                                        this.setState({
                                            calendar: fromCalendarDbStructureToCalendar(this.props.match.params.calendarId, calendarData.val())
                                        });
                                        let today = new Date();
                                        this.setState({ currentMonth: today.getMonth() + 1, currentDay: today.getDate() })
                                    })
                                    .catch(err2 => {
                                        console.log("[CalendarViewer] error getting calendar data:", err2);
                                    });
                            }
                        }
                        else {
                            this.setState({ showCalendarNotFound: true });
                        }
                    })
                    .catch(err => {
                        console.log("[CalendarViewer] error querying data:", err);
                    });
            }
            else {
                this.setState({ userId: null });
                if (isSignInWithEmailLink(auth, window.location.href)) {
                    let email = window.localStorage.getItem('emailForSignIn');
                    if (email) {
                        signInWithEmailLink(auth, email, window.location.href)
                            .then((result) => {
                                window.localStorage.removeItem('emailForSignIn');
                                console.log("[AuthenticationCompletion] Success signin in with email link:", result);
                            })
                            .catch((error) => {
                                // Some error occurred, you can inspect the code: error.code
                                // Common errors could be invalid email and invalid or expired OTPs.
                                console.log("[AuthenticationCompletion] error signin in with email link:", error, "error code:", error.code);
                                if (error.code === "auth/invalid-email") {
                                    //this.setState({isEmailInputVisibile: true});
                                }
                                else if (error.code === "auth/invalid-action-code") {
                                    //this.setState({ expiredLinkErrorVisible: true });
                                }
                            });
                    }
                    else {
                        this.setState({ showVerifyEmailDiv: true });
                    }
                }

            }
        });
    }

    setCardOpened(card: Card) {
        update(ref(database, `/calendars/advent/${this.state.userId}/${this.state.calendar?.id}/c/${card.id}/`), { o: true })
        if (this.state.calendar) {
            let tmpStateCalendar = this.state.calendar;
            tmpStateCalendar.c[tmpStateCalendar.c.indexOf(card)].o = true
            let newStateCalendar = tmpStateCalendar
            this.setState({ calendar: newStateCalendar })
        }

    }

    getMediaType(card: Card) {
        return card.f ? 'f' : card.i ? 'i' : card.t ? 't' : 'y';
    }

    canOpenCard(cardTimestamp: number) {
        const cardDate = new Date(cardTimestamp * 1000);
        //const nowDate = new Date("2021-12-22T00:00:01"); // FOR TESTING
        const nowDate = new Date(); // FOR PRODUCTION
        return nowDate > cardDate;
    }

    playAudio(audioUri: string) {
        const audioElement = (document.getElementById("calendarViewerAudioElement") as HTMLAudioElement);
        audioElement.src = audioUri;
        audioElement.play();
    }

    sendReward() {
        if (this.state.calendar)
            if (this.state.calendar?.c.filter(c => c.o === true).length >= 6) { //if there are 6 cards or more opened
                const sendCaalendarioGeduEmail = httpsCallable(functions, 'sendCaalendarioGeduEmail-sendCaalendarioGeduEmail');
                sendCaalendarioGeduEmail({
                })
                    .then((res: any) => {
                        //EMAIL SENT and R field Set to true
                        if (res.data.error) {
                            console.log("la cloud function ha dato errore:", res.data.error);
                        }
                    })
                    .catch(err => {
                        console.log('[] error calling cloud function', err);
                    })
                //show button for reward?
                //set a field to true
                //check that field in the home page
            }
    }

    render() {
        return (
            <IonPage>
                <audio
                    id="calendarViewerAudioElement"
                    autoPlay
                />

                {
                    this.state.calendar &&
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>
                                {`${this.state.userId && this.state.calendar ? this.state.calendar.t : "Caalendario"}`}
                            </IonTitle>

                            <IonButtons slot='start'>
                                <IonBackButton defaultHref="/home" />
                            </IonButtons>

                            {
                                this.state.calendar &&
                                <IonButton
                                    slot="end"
                                    fill="clear"
                                    onClick={() => {
                                        if (navigator.share) {
                                            navigator.share({
                                                title: "CAAlendario",
                                                text: `Guarda il "${this.state.calendar!.t}"`,
                                                url: `https://caalendario.it/calendar/advent/${this.state.calendar!.id}`
                                            })
                                        }
                                        else {
                                            this.setState({ showSharePopover: true });
                                        }
                                        if (this.state.userId && this.state.calendar) {
                                            const nowTimestamp = Math.floor((new Date()).getTime() / 1000);
                                            push(ref(database, `data-logging/${this.state.userId}/s`), {
                                                t: nowTimestamp,
                                                c: this.state.calendar.id
                                            })
                                                .then(data => {
                                                    console.log("[CalendarViewer] success pushing s:", data.key);
                                                })
                                                .catch(err => {
                                                    console.log("[CalendarViewer] error pushing s:", err);
                                                });
                                        }
                                    }}
                                >
                                    <IonIcon
                                        icon={share}
                                    />
                                </IonButton>
                            }
                        </IonToolbar>
                    </IonHeader>
                }

                <IonContent
                    className={this.state.userId ? (this.state.calendar?.settings.detailedBackground ? "calendarViewerContent" : "") : "calendarViewerContentBlurred"}
                >
                    {
                        this.state.showCalendarNotFound &&
                        <div style={{ textAlign: "center" }}>
                            <p>
                                Non abbiamo trovato il CAAlendario 🤷. Il link che hai inserito è quello corretto?
                            </p>
                            <a href="/">Torna alla home</a>
                        </div>
                    }

                    {
                        this.state.calendar &&
                        <div className="calendarViewerCardsDiv">
                            {
                                this.state.calendar?.c.map((card, i) => {
                                    return (
                                        <div key={i} className="calendarViewerDayCardDiv">
                                            <div
                                                className="calendarViewerDayCard"
                                                onClick={() => {
                                                    if (this.canOpenCard(card.d)) {
                                                        if (i == 24 && !card.o)
                                                            this.sendReward();
                                                        if (this.state.calendar && this.state.calendar.settings.soundOnCardTap) {
                                                            this.playAudio(itVoices[i]);
                                                        }
                                                        this.setState({ showDayPopover: true, dayContent: card, dayIndex: i, mediaType: this.getMediaType(card) })
                                                        if (card.i) {
                                                            fromGSUrltoUrl(card.i)
                                                                .then(url => {
                                                                    this.setState({ imgUrl: url });
                                                                })
                                                                .catch(err => {
                                                                    console.log("[CalendarViewer] error converting gs uri", err);
                                                                })
                                                        }
                                                        if (card.y) {
                                                            let videoId = card.y.substring(card.y.indexOf('=') + 1);
                                                            this.setState({ videoId: videoId })
                                                        }
                                                        if (card.f) {

                                                            fromGSUrltoUrl(card.f)
                                                                .then(url => {
                                                                    this.setState({ fileUrl: url })
                                                                    if (!isPlatform("desktop")) {
                                                                        window.open(url, "_blank");
                                                                    }
                                                                })
                                                                .catch(err => {
                                                                    console.log("[CalendarViewer] error converting file url:", err);
                                                                });

                                                        }
                                                        this.setCardOpened(card);

                                                    }
                                                    else {
                                                        console.log("[Calendar viewer] too soon");
                                                        this.setState({ showTooSoonPopover: true });
                                                    }
                                                }}
                                            >
                                                {
                                                    !this.state.calendar?.settings.pictogramCardIconVisible &&
                                                    <div className="calendarViewerCardTextDiv">
                                                        <p>
                                                            Giorno
                                                        </p>
                                                        <p>
                                                            <b>{i + 1}</b>
                                                        </p>
                                                    </div>
                                                }

                                                {/* {
                                                    this.state.calendar?.settings.pictogramCardIconVisible &&
                                                    <IonGrid style={{ height: "100%" }}>
                                                        <IonRow>
                                                            <IonCol>
                                                                <IonImg
                                                                    className="calendarViewerCardImage"
                                                                    src={fromDayNumberToImg((new Date(card.d * 1000).getDay()))}
                                                                    alt={fromDayNumberToDayName((new Date(card.d * 1000).getDay()))}
                                                                />
                                                            </IonCol>
                                                            <IonCol>
                                                                <IonImg
                                                                    className="calendarViewerCardImage"
                                                                    src={fromDayNumberToDayNumberImage((new Date(card.d * 1000).getDate()))}
                                                                />
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonImg
                                                                src={fromMonthNumberToMonthImage(12)}
                                                                alt="Dicembre"
                                                            />
                                                        </IonRow>
                                                    </IonGrid>
                                                } */}

                                                {
                                                    this.state.calendar?.settings.pictogramCardIconVisible &&
                                                    <div style={{ height: "100%", padding: "10px" }}>
                                                        <div style={{ height: "50%", width: "100%", display: 'flex', flexFlow: "row wrap" }}>

                                                            <img
                                                                style={{ height: "100%", width: "50%", objectFit: "fill" }}
                                                                src={fromDayNumberToImg((new Date(card.d * 1000).getDay()))}
                                                                alt={fromDayNumberToDayName((new Date(card.d * 1000).getDay()))}
                                                            />
                                                            <img
                                                                style={{ height: "100%", width: "50%", objectFit: "fill" }}
                                                                src={fromDayNumberToDayNumberImage((new Date(card.d * 1000).getDate()))}
                                                            />

                                                        </div>

                                                        <div style={{ height: "50%", width: "100%", display: 'flex', flexFlow: "row wrap" }}>
                                                            <img
                                                                style={{ height: "100%", objectFit: "fill", margin: "0 auto" }}
                                                                src={fromMonthNumberToMonthImage(4)}
                                                                alt="Marzo"
                                                            />
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }

                    <IonPopover
                        isOpen={this.state.showDayPopover}
                        onDidDismiss={() => {
                            this.setState({ showDayPopover: false })
                        }}
                        cssClass='dayPopover'
                        mode="md"
                    >
                        <h1 className='dayPopoverTitle'>
                            Giorno {this.state.dayIndex + 1}
                        </h1>
                        <div className='dayPopoverMainDiv'>

                            {this.state.dayContent &&
                                this.state.mediaType === 't' ? (
                                <IonRow className='dayPopoverRow'>
                                    <IonLabel>
                                        {this.state.dayContent.t}
                                    </IonLabel>
                                </IonRow>

                            ) : this.state.mediaType === 'f' ? (
                                <>
                                    <iframe
                                        onContextMenu={(e) => e.preventDefault()}
                                        src={`${this.state.fileUrl}${this.state.fileUrl ? "" : ''}`}
                                        height="500px"
                                        width="100%"
                                    />
                                </>

                            ) : this.state.mediaType === 'i' ? (
                                <>
                                    <img className='dayPopoverImage' src={this.state.imgUrl} alt='Nessuna immagine caricata' onLoad={(e) => e.currentTarget.style.display = 'inline-block'} onError={(e) => (e.currentTarget.style.display = 'none')} />
                                </>

                            ) : (
                                this.state.dayContent &&
                                <div className="calendarViewerDayPopoverIframeDiv">
                                    <iframe src={`https://www.youtube.com/embed/${this.state.videoId}`} width="100%" height="100%" allowFullScreen />
                                </div>
                            )
                            }
                        </div>


                    </IonPopover>

                    {
                        !this.state.userId &&
                        <div
                            className="calendarViewerAuthenticateOuterDiv"
                        >
                            <div
                                className="calendarViewerAuthenticateInnerDiv"
                                onClick={(e) => { e.stopPropagation() }}
                            >
                                {
                                    !this.state.showVerifyEmailDiv &&
                                    <div>
                                        <p>
                                            Per visualizzare questo Caalendario è necessario inserire la propria email.
                                        </p>
                                        <p>
                                            Richiediamo la email per proteggere la privacy di chi ha creato il Caalendario.
                                        </p>
                                    </div>
                                }

                                {
                                    !this.state.signInEmailSent &&
                                    !this.state.showVerifyEmailDiv &&
                                    <div>

                                        <IonInput
                                            placeholder="Email"
                                            value={this.state.email}
                                            onIonChange={e => {
                                                this.setState({ email: e.detail.value ? e.detail.value : "" });
                                            }}
                                            autocomplete="email"
                                        />


                                        <div className="calendarViewerVerifyEmailButtonOuterDiv">
                                            <div
                                                className="homeCreateNewCalendarButtonInnerDiv"
                                                onClick={() => {
                                                    const actionCodeSettings = {
                                                        url: `https://caalendario.it/calendar/advent/${this.props.match.params.calendarId}`,
                                                        /* url: `http://localhost:8100/calendar/advent/${this.props.match.params.calendarId}`, */
                                                        handleCodeInApp: true,
                                                    };

                                                    sendSignInLinkToEmail(auth, this.state.email, actionCodeSettings)
                                                        .then(() => {
                                                            console.log("[CalendarViewer] Success. Email sent");
                                                            window.localStorage.setItem('emailForSignIn', this.state.email);
                                                            this.setState({ signInEmailSent: true });
                                                        })
                                                        .catch((error) => {
                                                            console.log("[CalendarViewer] error sending magic link:", error);
                                                        });
                                                }}
                                            >
                                                <a className="homeCreateNewCalendarButton">
                                                    Verifica la email
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {
                                    this.state.showVerifyEmailDiv &&
                                    <div>
                                        <p className="calendarViewerVerifyEmailExplanationText">
                                            Probabilmente questo dispositivo non è lo stesso da cui ha richiesto il link di accesso.
                                        </p>
                                        <p className="calendarViewerVerifyEmailCTAText">
                                            Per sicurezza, reinserisci la email e possiamo iniziare!
                                        </p>

                                        <IonInput
                                            className="calendarViewerVerifyEmailInput"
                                            placeholder="Email"
                                            value={this.state.email}
                                            onIonChange={e => {
                                                this.setState({ email: e.detail.value ? e.detail.value : "" });
                                            }}
                                            autocomplete="email"
                                        />

                                        <div className="calendarViewerVerifyEmailButtonOuterDiv">
                                            <div
                                                className="homeCreateNewCalendarButtonInnerDiv"
                                                onClick={() => {
                                                    signInWithEmailLink(auth, this.state.email, window.location.href)
                                                        .then((result) => {
                                                            window.localStorage.removeItem('emailForSignIn');
                                                            console.log("[CalendarViewer] Success signin in with email link:", result);
                                                        })
                                                        .catch((error) => {
                                                            window.localStorage.removeItem('emailForSignIn');
                                                            console.log("[CalendarViewer] error signin in with email link:", error, "error code:", error.code);
                                                        });
                                                }}
                                            >
                                                <a className="homeCreateNewCalendarButton">
                                                    Conferma la email
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {
                                    this.state.signInEmailSent &&
                                    <p>
                                        Email inviata. Controlla la posta di <a href={`https://${this.state.email.split("@").pop()}`} target="_blank">{this.state.email}</a>
                                    </p>
                                }

                            </div>
                        </div>
                    }
                </IonContent>

                <IonPopover
                    isOpen={this.state.showSharePopover}
                    onDidDismiss={() => { this.setState({ showSharePopover: false }) }}
                    cssClass="calendarViewerSharePopover"
                >
                    <p className="calendarViewerSharePopoverText">
                        <b>Per condividere il CAAlendario invia questo link 👇</b>
                    </p>
                    {
                        this.state.calendar &&
                        <div className="calendarViewerSharePopoverCopyLinkDiv">
                            <p className="calendarViewerSharePopoverCopyLinkText">
                                <i>{`https://caalendario.it/calendar/advent/${this.state.calendar.id}`}</i>
                            </p>
                            <IonButton
                                onClick={() => {
                                    navigator.clipboard.writeText(`https://caalendario.it/calendar/advent/${this.state.calendar!.id}`)
                                }}
                            >
                                <IonIcon
                                    icon={copy}
                                />
                            </IonButton>
                        </div>
                    }
                </IonPopover>

                <IonPopover
                    isOpen={this.state.showTooSoonPopover}
                    mode="md"
                    onDidDismiss={() => {
                        this.setState({ showTooSoonPopover: false });
                    }}
                >
                    <p style={{ textAlign: "center", fontSize: "large" }}>
                        Troppo presto, attendi che arrivi il giorno! ⏳🤶🎅
                    </p>
                </IonPopover>

                {
                    !this.props.unmutePressed &&
                    this.state.userId &&
                    <div
                        className="calendarViewerUnmuteButtonDiv"
                        onClick={() => {
                            const audioElement = (document.getElementById("calendarViewerAudioElement") as HTMLAudioElement);
                            audioElement.muted = false;
                            this.props.unmute(true);
                        }}
                    >
                        <p className="calendarViewerUnmuteButtonParagraph">
                            Attiva l'audio
                        </p>
                        <IonIcon
                            className="calendarViewerUnmuteButtonIcon"
                            icon={notifications}
                        />
                    </div>
                }
            </IonPage>

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        unmutePressed: state.settings.unmutePressed,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        unmute: (pressed: boolean) => {
            dispatch(settingsActions.unmute(pressed));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarViewer);