import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger'; // NOT FOR PRODUCTION

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import Authentication from '../modules/Authentication/store/reducers';
import Settings from '../modules/Settings/store/reducers';

const middlewares = [thunk, logger];
//const middlewares = [thunk];

const rootReducer = combineReducers({
    auth: Authentication,
    settings: Settings
});

const persistConfig = {
    key: 'root',
    storage,
    blacklist: [],
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

let store = createStore(persistedReducer, applyMiddleware(...middlewares));
let persistor = persistStore(store);

export { store, persistor }