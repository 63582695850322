import { settingsConstants } from './constants';
import { Dispatch } from 'redux';

export const settingsActions = {
    colorImages,
    changeCategoryFilter,
    unmute,
};

export const actionCreator = {
    colorImages: (color: boolean) => { return { type: settingsConstants.GRAYSCALE_IMAGES, color } },
    changeCategoryFilter: (filter: string[]) => { return { type: settingsConstants.CHANGE_FILTER, filter } },
    unmute: (pressed: boolean) => { return { type: settingsConstants.UNMUTE_PRESSED, pressed } },
};

function colorImages(grayscale: boolean) {
    return (dispatch: Dispatch) => {
        dispatch(actionCreator.colorImages(grayscale));
    };
}

function changeCategoryFilter(filter: string[]) {
    return (dispatch: Dispatch) => {
        dispatch(actionCreator.changeCategoryFilter(filter));
    };
}

function unmute(pressed: boolean) {
    return (dispatch: Dispatch) => {
        dispatch(actionCreator.unmute(pressed));
    };
}