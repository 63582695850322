import {
    IonAlert,
    IonBackButton,
    IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonContent, IonPage, IonTitle
} from '@ionic/react';
import { deleteUser, signOut } from "firebase/auth";
import { deleteObject, listAll, ref as storageRef } from 'firebase/storage';
import React from 'react';
import { connect } from 'react-redux';
import { auth, storage } from '../../../common/firebase';
import { authActions } from '../../Authentication/store/actions';
import "./Profile.css";

type Props = {
    history: any,

    logout: () => void,
};

type State = {
    email: string,
    showDeleteAccountAlert: boolean
};



class Profile extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            email: "",
            showDeleteAccountAlert: false,
        }
    }

    componentDidMount() {
        auth.onAuthStateChanged(userData => {
            if (userData) {
                this.setState({ email: userData.email! })
            }
        })
    }

    deleteUser() {
        let user = auth.currentUser;
        if (user) {
            listAll(storageRef(storage, `users-media/${user!.uid}/calendars/advent/`))
                .then((res) => {
                    console.log(res);
                    res.prefixes.forEach((folderRef) => {
                        alert(folderRef.name)
                        listAll(storageRef(storage, `users-media/${user!.uid}/calendars/advent/${folderRef.name}/`))
                            .then((res) => {
                                console.log("LIST ALL FILES", res);
                                res.items.forEach(fileRef => {
                                    deleteObject(storageRef(storage, `users-media/${user!.uid}/calendars/advent/${folderRef.name}/${fileRef.name}`))
                                })
                            })
                    })
                })
                .then(() => {
                    deleteUser(user!).then(() => {
                        signOut(auth).then(() => {
                            this.props.history.push('/landing-page');
                        })

                    })
                })
                .catch(error => {
                    console.log("ERRORE", error)
                })
        }
    }


    render() {
        return (
            <IonPage>
                <IonContent className="profileContent">
                    <div
                        id="profileContentDiv"
                        className="profileContentDiv"
                    >
                        <IonButtons slot='end'>
                            <IonBackButton defaultHref='/' />
                        </IonButtons>

                        <div className='profileTitleDiv'>

                            <IonTitle className='profileTitle'>Il tuo profilo</IonTitle>
                        </div>
                        <div className='profileUserDataDiv'>
                            <IonCard>
                                <IonCardHeader className='profileCardHeader'>Indirizzo Email</IonCardHeader>
                                <IonCardContent className='profileCardContent'>{auth.currentUser?.email}</IonCardContent>
                            </IonCard>
                        </div>
                        <div className='profileLogoutDiv'>
                            <IonButton
                                fill='clear'
                                onClick={() => {
                                    this.props.logout();
                                    window.location.replace('/landing-page')
                                }}
                                className='profileLogoutButton'
                            >
                                Esci
                            </IonButton>
                        </div>
                        <div className='profileLogoutDiv'>
                            <IonButton
                                fill='clear'
                                style={{ textTransform: 'none' }}
                                onClick={() => {
                                    this.setState({ showDeleteAccountAlert: true })
                                }}
                            >
                                Elimina account
                            </IonButton>
                        </div>
                    </div>
                </IonContent>
                <IonAlert
                    isOpen={this.state.showDeleteAccountAlert}
                    header='Attenzione'
                    onDidDismiss={() => this.setState({ showDeleteAccountAlert: false })}
                    message={`Vuoi davvero eliminare l'account?`}
                    buttons={[
                        {
                            text: 'Elimina',
                            handler: () => {
                                this.deleteUser();
                            }
                        },
                        {
                            text: 'Annulla',
                            handler: () => {
                                this.setState({ showDeleteAccountAlert: false })
                                return
                            }
                        }
                    ]}
                />
            </IonPage >

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        logout: () => {
            dispatch(authActions.logout());
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);

